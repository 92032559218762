<template>
  <div class="content">
    <div class="main-screen-section inside screen2"></div>

    <div class="container inside-page content-section">
      <h1>Патогенез мигрени</h1>
      <div class="content-text text-size-big mb20">
        <p>
          В 1930-х годах мигрень объясняли «Сосудистой теорией», затем в 1983
          стали объяснять «Нейрогенной теорией». Однако, по отдельности они не
          объясняют патофизиологию мигрени полностью.
        </p>
      </div>

      <div class="content-text text-size-middle mb50">
        <p>
          Согласно принятой в настоящее время «Нервно-сосудистой теории»,
          симптомы мигрени вызваны взаимодействием черепных кровеносных сосудов
          и нервной системы. Мигрень начинается с ряда неврологических событий,
          которые ведут к различным внутри- и внечерепным изменениям.
        </p>
      </div>

      <h3>Причины боли</h3>

      <div class="causes-block">
        <div class="theory-col1">
          <div class="theory">
            <div class="title">
              Сосудистая теория
            </div>
            <ul class="list-block">
              <li>сужение кровеносных сосудов в черепе</li>
              <li>вазодилатация (эффект «отдачи»)</li>
            </ul>
          </div>
          <div class="plus"></div>
          <div class="theory">
            <div class="title">
              Нейрогенная теория
            </div>
            <ul class="list-block">
              <li>
                кортикальная распространяющаяся депрессия <br />
                (кора головного мозга)
              </li>
              <li>тригеминальная система (ствол головного мозга)</li>
            </ul>
          </div>
        </div>
        <div class="theory-col2">
          <div class="theory">
            <div class="title">
              Нервно-сосудистая теория
            </div>
            <ul class="list-block">
              <li>
                кортикальная распространяющаяся <br />
                депрессия (кора головного мозга)
              </li>
              <li>тригеминальная система (ствол головного мозга)</li>
              <li>
                изменения в сосудах из-за высвобождения нейропептидов <br />
                и провоспалительных веществ
              </li>
            </ul>
          </div>
        </div>
      </div>

      <h3 class="mb30">Роль CGRP</h3>

      <div class="video-block">
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/qagby2Lz_FE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <!-- <span class="play-ico"></span> -->
      </div>

      <div class="content-text text-size-middle">
        <p>
          Мигрень — это нейровоспалительное заболевание: после активации
          тройничные чувствительные нейроны в мозговых оболочках высвобождают
          провоспалительные нейропептиды, такие как CGRP.
        </p>
        <p>
          Нейропептиды взаимодействуют с кровеносными сосудами в мозговых
          оболочках, провоцируя вазодилатацию и экстравазацию белков плазмы
          крови. Нейропептиды индуцируют дегрануляцию тучных клеток и
          вырабатывают дополнительные провоспалительные модуляторы. Этот процесс
          получил название «стерильное нейрогенное воспаление».
        </p>
        <p class="mb40">
          Нейрогенное воспаление влияет на то, как головной мозг воспринимает
          боль.
        </p>

        <h3 class="mb20">
          Роль CGRP <span class="hidden-xs">при нейрогенном воспалении</span>
        </h3>

        <div class="unlist">
          <div class="mb40">
            <div class="row">
              <div class="col-md-4">
                <div class="sub-list-title">
                  <span class="semibold">Вазодилатация</span>
                </div>
                <ul class="list-block">
                  <li>
                    CGRP встречается по всей сосудистой сети головного мозга.
                  </li>
                  <li>
                    CGRP индуцирует дилатацию средних мозговых и средних
                    менингеальных артерий.
                  </li>
                  <li>
                    CGRP стимулирует кровоток <br />
                    во время КРД, что предшествует головной боли.
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <div class="sub-list-title">
                  <span class="semibold">Экстравазация белков плазмы</span>
                </div>
                <ul class="list-block">
                  <li>
                    Белки плазмы покидают <br class="hidden-xs" />
                    твердую мозговую оболочку <br />
                    и индуцируют боль.
                  </li>
                  <li>
                    ЭБП вызывается субстанцией <br />
                    P и нейрокинином, которые высвобождаются
                    <br class="hidden-xs" />
                    одновременно с CGRP.
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <div class="sub-list-title">
                  <span class="semibold">Дегрануляция тучных клеток</span>
                </div>
                <ul class="list-block">
                  <li>
                    Активация и дегрануляция тучных клеток высвобождает
                    гистамин, который может индуцировать мигрень.
                  </li>
                  <li>
                    Активация тучных клеток может активировать первичные
                    афференты <br class="visible-xs" />
                    в твердой мозговой оболочке, что приводит
                    <br class="hidden-xs" />
                    к иммунореактивности в ядре тройничного нерва.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="clinical-data">
          <div class="title">
            Клинические данные <br class="visible-xs" />
            подтверждают роль CGRP <br class="visible-xs" />
            при мигрени:
          </div>
          <ul class="list-block">
            <li>
              Во время приступа мигрени уровни CGRP в крови, сыворотке крови и
              слюне склонны <br />
              к повышению;
            </li>
            <li>
              В/в инъекция CGRP провоцирует мигренеподобные головные боли;
            </li>
            <li>
              Эффективное лечение мигрени нормализует уровни CGRP в крови;
            </li>
            <li>Антагонисты рецептора CGRP эффективны в лечении мигрени.</li>
          </ul>
        </div>

        <p>
          Понимание роли кальцитонин-ген-родственного пептида (CGRP) в
          патофизиологии мигрени, а также достижения в области мАт облегчили
          разработку специфических профилактических терапий при мигрени,
          направленных на CGRP.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: 'Патогенез мигрени',
    meta: [
      { name: 'description', content: 'Головные боли одни из распространенных расстройств нервной системы и существенно нарушают качество жизни и трудоспособность' }
    ]
  }
}
</script>
